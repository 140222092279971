<template>
  <div id="app">
    <div id="zbj-call"></div>
  </div>
</template>

<script>
import { Dialog } from "vant";
const zbjccState = {
  1: "离线",
  2: "空闲",
  3: "挂起",
  4: "外呼",
  5: "来电",
  6: "通话中",
  7: "整理中",
  8: "咨询中",
  9: "咨询来电",
  10: "咨询外呼",
};
export default {
  name: "App",
  data() {
    return {
      // sdkUrl: 'https://callcenterapi-test.zbjdev.com/zbjcc/loadzbjcc.js?v=',

      sdkUrl: "https://callcenterapi.zbj.com/zbjcc/loadzbjcc.js?v=",
      token: undefined,
      caller: null,
      phoneNumber: "",
      sdkOptions: {
        hasSelfLoader: false,
        wraperElement: "#zbj-call",
        token: "",
        test: false,
      },
    };
  },
  mounted() {
    this.appendScript().then((zbjcallcenter) => {
      let { t, p } = this.parseQueryString(window.location.search);
      if (p) {
        this.phoneNumber = p;
      }
      if (t) {
        this.sdkOptions.token = t;
        this.initSDK(zbjcallcenter);
      }
    });
  },
  methods: {
    parseQueryString(queryString, decode) {
      var config = {};
      var index = queryString.indexOf("?");
      if (index > -1) {
        queryString
          .substr(index + 1)
          .split("&")
          .forEach(function (str) {
            if (!str.length) {
              return;
            }
            var query = str.split("=");
            var key = query.shift();
            var isArr = key.slice(-2) === "[]";
            if (isArr) {
              key = key.slice(0, -2);
              if (!config[key]) {
                config[key] = [];
              }
            }
            var value = query.shift();
            if (!value || !value.length) {
              return;
            } else if (value === "true") {
              value = true;
            } else if (value === "false") {
              value = false;
            } else if (!isNaN(value)) {
              value = Number(value);
            } else if (typeof value === "string") {
              if (decode) {
                value = decodeURIComponent(value);
              }
            }
            if (isArr) {
              config[key].push(value);
            } else {
              config[key] = value;
            }
          });
      }
      return config;
    },
    // 检测当前迁入状态
    checkStatus() {
      if (this.caller) {
        const state = this.caller.getStatus();
        console.log("---------------");
        console.log(zbjccState[state]);
        console.log("---------------");
        if (state === 1) {
          // Dialog({
          //   title: '钉耙获客',
          //   message: '外呼坐席登录成功，您当前状态为离线，请点击顶部灰色下拉框切换为空闲状态开始使用哦～',
          //   confirmButtonColor: '#12d2cb',
          //   confirmButtonText: '我知道了～'
          // })
          this.signin();
        }
      }
    },
    // 绑定拨号震动
    bindVibration() {
      document.querySelectorAll(".key-item").forEach((item) => {
        item.addEventListener("click", () => {
          window.navigator.vibrate(10);
        });
      });
    },
    // 迁入
    signin() {
      if (this.caller) {
        console.log("------------");
        console.log("开始迁入");
        console.log("------------");
        this.caller
          .signin(this.phoneNumber, 2, 2)
          .then((res) => {
            console.log(res);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    // 初始化SDK
    initSDK(zbjcallcenter) {
      if (!zbjcallcenter) {
        return;
      }
      this.caller = zbjcallcenter(this.sdkOptions);
      this.caller.on("onInit", () => {
        console.log("初始化完成");
        this.bindVibration();
        this.checkStatus(zbjcallcenter);
      });

      this.caller.on("onRing", (data) => {
        console.log("onRing trigger 回调参数如下：", data);
      });
      this.caller.on("onTalking", (data) => {
        console.log("onTalking trigger 回调参数如下：", data);
      });
      this.caller.on("onHangup", (data) => {
        console.log("onHangup trigger 回调参数如下：", data);
      });
      this.caller.on("onError", (data) => {
        console.log(data);
        if (data.code === 60000) {
          Dialog({
            title: "钉耙获客",
            message: "不能拨打自己的电话号码哦～",
          });
        } else if (data.code === "10612" || data.code === 10612) {
          Dialog({
            title: "钉耙获客",
            message: "哎呀，外呼系统登录失败，请联系客服或稍后重试～",
            confirmButtonText: "重试",
          }).then(() => {
            window.location.reload();
          });
        } else if (data.msg) {
          Dialog({
            title: "钉耙获客",
            message: data.msg,
            confirmButtonColor: "#12d2cb",
          });
        }
        console.error("onError trigger 回调参数如下：", data);
      });
    },
    appendScript() {
      return new Promise((resolve, reject) => {
        const dom = document.querySelector("#zbjphone");
        if (!dom) {
          const script = document.createElement("script");
          script.src = this.sdkUrl + Math.random() * 100;
          script.setAttribute("id", "zbjphone");
          script.onload = () => {
            resolve(window.zbjcallcenter);
          };
          script.onerror = function (e) {
            reject(e);
            document.body.removeChild(this);
          };
          document.body.appendChild(script);
        } else {
          resolve(window.zbjcallcenter);
        }
      });
    },
  },
};
</script>

<style lang="scss">
$color-border: #eee;
$color-brand: #12d2cb;
* {
  padding: 0;
  margin: 0;
}
#zbj-call {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .drop-modal-header {
    display: none !important;
  }
  .zbjcc-call-bar {
    border-radius: 0 !important;
    width: 100%;
    height: 0.8rem;
    position: relative;
    align-items: center;
    .zbjccicon-sdk-zhankai {
      display: none;
    }
    .ember-basic-dropdown {
      position: absolute;
      left: 0.5rem;
      height: 0.8rem;
      line-height: 0.8rem;
      .ember-power-select-trigger {
        font-size: 0.3rem !important;
      }
    }
    .count-time {
      position: absolute;
      border: none;
      text-align: right;
      right: 0;
      top: 0;
      height: 0.8rem;
      line-height: 0.8rem;
      display: block;
      padding-right: 0.5rem;
      font-size: 0.3rem !important;
      width: 3rem;
    }
  }
  .dialing-wraper {
    top: 0.6rem;
    // position: relative;
    // z-index: 4;
    .zbjcc-drop-content {
      padding-top: 0.4rem;
      box-shadow: none;
      // width: 37.5rem;
      width: 10rem;
      background: none !important;
      display: block !important;
      height: auto;
      .number-input {
        height: 1.3333rem;
        border: 2px solid $color-border;
        border-radius: 0.8rem;
        width: 9rem;
        font-size: 0.473rem;
        margin-bottom: 0.8rem;
        -webkit-appearance: none;
        font-weight: bold;
        &:focus {
          border-width: 2px !important;
        }
      }
      .dialing-key-wraper {
        justify-content: space-between;
        width: 6.1333333rem;
        &::after {
          content: "";
          position: fixed;
          left: 0;
          right: 0;
          background: #fff;
          top: 3rem;
          bottom: 0;
          z-index: -1;
          border-top-left-radius: 0.6rem;
          border-top-right-radius: 0.6rem;
          box-shadow: 0px -2px 5px rgb(0 0 0 / 10%);
          // border-top: 2px solid #eee;
        }
        .key-item {
          width: 1.8rem;
          height: 1.8rem;
          line-height: 1.8rem;
          font-size: 0.4266666666666667rem;
          border-color: $color-border;
          font-weight: bold;
          border-width: 2px;
          user-select: none;
          &:active {
            border-color: $color-brand;
            color: $color-brand;
          }
        }
      }
      .c-btn {
        width: 1.8rem;
        height: 1.8rem;
        line-height: 1.8rem;
        font-size: 0.4266666666666667rem;
        -webkit-appearance: none;
        border-width: 2px;
      }
      .relayNumber {
        display: none;
      }
      .switch-way {
        display: none;
        .input-wraper {
        }
      }
    }
  }
  .talking-btns {
    padding-top: 4rem;
    .call-btns-wraper {
      width: 6.64rem;
      .call-btn {
        .center-content {
          width: 1.8rem;
          height: 1.8rem;
          line-height: 1.8rem;
          font-size: 0.4266666666666667rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
        }
      }
    }
  }
}
</style>
